<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 站房管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>设备管理</el-breadcrumb-item>
        <el-breadcrumb-item>国标反控</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-button @click="handleGoBack" plain icon="el-icon-back" size="mini">
          返回上一页</el-button
        >
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-input clearable size="mini" v-model="accessPasswordInput">
            <template #prepend>访问密码:</template>
          </el-input>
        </el-col>
        <div class="block" style="margin-left: 1%">
          <span class="demonstration">选择协议版本 </span>
          <el-select
            size="mini"
            v-model="select.version"
            placeholder="协议版本"
          >
            <el-option
              v-for="item in options.versionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-row>
    </el-card>
    <el-card shado="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="operationName" label="操作名称">
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <div>
              <el-button
                size="mini"
                type="text"
                @click="handleSetReissueData(scope.$index, scope.row)"
              >
                设置
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <set-reissue-data-dialog
      :dialog-visible="dialogVisibleSetReissueData"
      @updateVisible="closeSetReissueData"
    >
    </set-reissue-data-dialog>
  </div>
</template>

<script>
import router from "@/router";
import SetReissueDataDialog from "@/views/room/countercharge/dialog/SetReissueDataDialog";

export default {
  name: "Countercharge",
  components: { SetReissueDataDialog },
  data() {
    return {
      accessPasswordInput: "123456",
      dialogVisibleSetReissueData: false,
      tableData: [
        {
          operationName: "补发数据",
          url: "/countercharge/reissue-data/",
        },
      ],
      options: {
        versionOptions: [
          {
            value: "2005",
            label: "2005",
          },
          {
            value: "2017",
            label: "2017",
          },
        ],
      },
      select: {
        version: "2005",
      },
      params: {},
      requestBody: {},
    };
  },
  methods: {
    handleGoBack() {
      router.go(-1);
      this.$store.commit("closeCurrentTag", {
        $router: this.$router,
        $route: this.$route,
      });
    },
    handleSetReissueData(index, row) {
      console.log(index, row);
      this.dialogVisibleSetReissueData = true;
    },
    closeSetReissueData() {
      this.dialogVisibleSetReissueData = false;
    },
  },
};
</script>

<style scoped></style>
